import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../api/apihandler';
import { CMS_DETAILS } from "../api/constants";

// images
import loader from '../assets/images/page-img/page-load-loader.gif'
import imgOne from '../assets/images/becomeinstructor/1.webp'
import imgTwo from '../assets/images/becomeinstructor/2.webp'


const BecomeInstructor = ({ }) => {
    const [cmsDetailsData, setCMSDetailsData] = useState('');
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        getCMSDetails("home")
    }, [])


    async function getCMSDetails(slug) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(CMS_DETAILS, "POST", {
                slug: slug
            })
            if (res.data?.response?.status === 200) {
                setCMSDetailsData(res?.data?.data?.cmsPage)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }


    return (
        <>
            <div>
                {
                    cmsDetailsData?.cms_page_body?.length > 0 ? cmsDetailsData?.cms_page_body?.map((elm, i) => (
                        <>
                            {
                                i % 2 === 0 ?
                                    <div className="d-flex flex-wrap" style={{ justifyContent: "space-evenly", background: "#F5F7FE",  paddingTop: "70px", paddingBottom: "70px" }}>
                                        <div>
                                            <img src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                className="img-fluid w-10" alt="header-bg" style={{ maxHeight: "300px", borderRadius: "6px" }} />
                                        </div>
                                        <div className="d-flex align-items-center" style={{marginLeft: "20px"}}>
                                            <div>
                                                <h1 style={{fontSize: "3.6705rem"}}>{elm?.heading}</h1>
                                                <p className="pt-3 pb-3" style={{ maxWidth: "80%", fontSize: "20px"}}>{elm?.sub_heading}</p>
                                                {/* <div >

                                                </div> */}
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="d-flex flex-wrap" style={{ justifyContent: "space-evenly", background: "#FFF",  paddingTop: "70px", paddingBottom: "70px" }}>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <h1 style={{fontSize: "3.6705rem"}}>{elm?.heading}</h1>
                                                <p className="pt-3 pb-3" style={{ maxWidth: "80%", fontSize: "20px" }}>{elm?.sub_heading}</p>
                                                {/* <div >

                                                </div> */}
                                            </div>
                                        </div>
                                        <div>
                                            <img src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                className="img-fluid w-10" alt="header-bg" style={{ maxHeight: "350px", borderRadius: "6px" }} />
                                        </div>
                                    </div>
                            }
                        </>
                    )) : null
                }

            </div>
            {
                showMoreDataLoader ?
                    <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                    </div> : null
            }
        </>

    )
}

export default BecomeInstructor
