import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import Loader from "react-js-loader";
import { apiHandler } from '../../api/apihandler';
import { EVENT_MEMBERS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'

// images
import user05 from '../../assets/images/user/05.jpg'
import user06 from '../../assets/images/user/06.jpg'
import user07 from '../../assets/images/user/07.jpg'
import user08 from '../../assets/images/user/08.jpg'
import user09 from '../../assets/images/user/09.jpg'
import EventParticipateModal from './eventParticipateModal'
import ParticipatePaymentPopup from './participatePaymentPopup'
// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const MyParticipationEventCard = ({ elm, getEventList, isPast }) => {
    const navigate = useNavigate();
    const [memberList, setMemberList] = useState([]);
    const [showTicketBuy, setShowTicketBuy] = useState(false);
    const [showParticipateModal, setShowParticipateModal] = useState(false);

    useEffect(() => {
        //getMembersList()
    }, [])

    async function getMembersList() {
        try {
            const res = await apiHandler(EVENT_MEMBERS, "POST", {
                event_id: elm?.id,
                page: 1,
                limit: 6
            })
            if (res.data?.response?.status === 200) {
                setMemberList(res?.data?.data?.eventParticipants?.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    return (
        <>
            {/* <div style={{ width: "33%" }}> */}
            <div>
                <Card className="rounded  mb-0" >
                    <div className="event-images">
                        <Link to={`/dashboards/app/event-detail/${elm?.event?.slug}`}>
                            <img src={elm?.event?.image ? elm?.event?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                className="img-fluid" alt="Responsive"
                                style={{ height: "180px", width: "100%", objectFit: "initial" }} />
                        </Link>
                    </div>
                    <Card.Body>
                        <div>
                            <div className="d-flex w-100">
                                <div className="date-of-event">
                                    <span>{Moment(elm?.event?.start_date).format('MMM')}</span>
                                    <h5>{Moment(elm?.event?.start_date).format('DD')}</h5>
                                </div>
                                <div className="events-detail ms-3">
                                    <div className='d-flex align-items-center'>
                                        <h5><Link to={`/dashboards/app/event-detail/${elm?.event?.slug}`}
                                            onClick={() => {
                                                localStorage.setItem('eventIsPast', isPast)
                                            }}>{elm?.event?.name}</Link></h5>
                                        {
                                            isPast ? null :
                                                elm?.payment_status === 1 ?
                                                    <div className='d-flex align-items-center ms-2'>
                                                        <h6 style={{ color: "#20c997" }}><i class="ri-checkbox-circle-line"></i>Paid </h6>
                                                    </div> : null
                                        }
                                    </div>
                                    <p>{elm?.event?.city + ", " + elm?.event?.state + ", " + elm?.event?.zip}</p>
                                </div>

                                {/* <div className="card-post-toolbar">
                                    <h5>{"$" + elm?.price}</h5>
                                </div> */}
                            </div>

                            {
                                isPast ? null :
                                    elm?.payment_status === 0 ?
                                        <div className="d-flex">
                                            <button type="submit" className="btn btn-primary d-block w-100" style={{ marginRight: "5px" }}
                                                onClick={() => {
                                                    if (localStorage.getItem("uzoneAuthToken")) {
                                                        setShowTicketBuy(true)
                                                    } else {
                                                        navigate('/auth/sign-in')
                                                    }
                                                }}
                                            >Pay Now {"$" + elm?.event?.price}</button>
                                        </div> : null
                            }
                        </div>
                    </Card.Body>
                </Card>
                <ParticipatePaymentPopup elm={elm?.event} showTicketBuy={showTicketBuy} setShowTicketBuy={setShowTicketBuy} getEventList={getEventList} />
            </div>
        </>
    )
}

export default MyParticipationEventCard
