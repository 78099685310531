import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Image, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../api/apihandler';
import { BLOG_LIKE, BLOG_LIKES, BLOG_LIST } from '../../api/constants'
import Swal from 'sweetalert2'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import BlogCommentBox from './blogCommentBox';
import BlogCommentList from './blogCommentList';
import LoaderModal from '../loaderModal';
import DOMPurify from "isomorphic-dompurify";

// img
import likeFillIcon from '../../assets/images/icon/like_fill_icon.png'
import likeIcon from '../../assets/images/icon/like_icon.png'
import playButton from '../../assets/images/icon/play_button.png'
import BlogCommentModal from './blogCommentModal';


const BlogCard = ({ elm, index }) => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [likesList, setLikesList] = useState([]);
    const [showLikesList, setShowLikesList] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [refreshCommentList, setRefreshCommentList] = useState(false);

    const handleClose = () => {
        setShowCommentModal(false)
    }
    const handleShow = () => {
        setShowCommentModal(true);
    }

    useEffect(() => {
        getBlogLikesList()
        //   if (localStorage.getItem("uzoneAuthToken")) {
        //      getBlogList(1)
        //   } else {
        //      navigate('/auth/sign-in')
        //   }
    }, []);

    async function getBlogLike() {
        if (localStorage.getItem("uzoneAuthToken")) {
            setShowLoader(true)
            try {
                const res = await apiHandler(BLOG_LIKE, "POST", {
                    blog_id: elm?.id
                })
                if (res.data?.response?.status === 200) {
                    if (elm?.is_like === 1) {
                        elm['is_like'] = 0
                        elm['like_count'] = elm?.like_count - 1
                    } else {
                        elm['is_like'] = 1
                        elm['like_count'] = elm?.like_count + 1
                    }
                    // getFeedLikesUserList(elm?.id)
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setShowLoader(false)
            }
        } else {
            navigate('/dashboard/app/home-page')
        }
    }

    async function getBlogLikesList() {
        try {
            const res = await apiHandler(BLOG_LIKES, "POST", {
                blog_id: elm?.id,
                page: 1,
                limit: 100
            })
            if (res.data?.response?.status === 200) {
                setLikesList(res?.data?.data?.bloglikes?.data)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    let order1 = (index + 1) % 2 === 0 ? 2 : 1
    let order2 = (index + 1) % 2 === 0 ? 1 : 2

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <Col lg="12">
                <Card className="card-block card-stretch card-height blog-list list-even">
                    <Card.Body>
                        <Row className="align-items-center" style={{ justifyContent: "flex-end" }}>
                            <Col md="6" style={{ order: order1 }}>
                                <div className="blog-description rounded p-2">
                                    <div className="date mb-2">
                                        {/* <Link to="#" tabIndex="-1">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</Link> */}
                                        <h6 tabIndex="-1">{Moment(elm?.updated_at).format('DD MMMM YYYY')}</h6>
                                    </div>
                                    <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`} className="mb-2">{elm?.title}</Link>
                                    {
                                        elm?.body ?
                                            <h6 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((elm?.body).substring(0, 350) + (elm?.body?.length > 350 ? "..." : "")) }}/> //{(elm?.body).substring(1, 350)}</p> 
                                            : null
                                    }
                                    {
                                        elm?.body?.length > 350 ?
                                            <Link to="#" tabIndex="-1" className="d-flex align-items-center"
                                                onClick={() => { handleShow() }}>Read More
                                                <i className="material-symbols-outlined md-14 filled">arrow_forward_ios</i>
                                            </Link> : null
                                    }

                                    <div className="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between position-right-side">
                                        <div className="iq-media-group">
                                            <div className="comment d-flex align-items-center" style={{ cursor: "pointer" }}>
                                                {
                                                    elm?.is_like === 1 ?
                                                        <img loading="lazy" src={likeFillIcon} alt="icon" className="img-fluid" style={{ marginTop: "-2px", cursor: "pointer" }}
                                                            onClick={() => { getBlogLike() }} /> :
                                                        <img loading="lazy" src={likeIcon} alt="icon" className="img-fluid" style={{ marginTop: "-5px", cursor: "pointer" }}
                                                            onClick={() => { getBlogLike() }} />
                                                }
                                                {
                                                    elm?.is_like === 1 ?
                                                        elm?.like_count > 1 ?
                                                            <div
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>
                                                                You and {((elm?.like_count) - 1) + " "} others
                                                            </div> :
                                                            <div
                                                                onMouseEnter={() => setShowLikesList(true)}
                                                                onMouseLeave={() => setShowLikesList(false)}>
                                                                You like
                                                            </div> :
                                                        <div style={{ marginLeft: "6px" }}
                                                            onMouseEnter={() => setShowLikesList(true)}
                                                            onMouseLeave={() => setShowLikesList(false)}>
                                                            {elm?.like_count + " "}like
                                                        </div>
                                                }
                                                {
                                                    showLikesList ?
                                                        <div className="card-header-toolbar d-flex align-items-center" style={{ position: "absolute" }}
                                                            onMouseEnter={() => setShowLikesList(true)}
                                                            onMouseLeave={() => setShowLikesList(false)}>
                                                            <Dropdown>
                                                                <Card className="dropdown-menu-right cardBackgroundColor">
                                                                    <div style={{ backgroundColor: "#e4f0f1", margin: "1px" }}>
                                                                        {
                                                                            likesList ?
                                                                                likesList?.map((elm, i) => (
                                                                                    <>
                                                                                        <Dropdown.Item
                                                                                            key={elm?.id}
                                                                                            href={`/dashboard/app/friend-profile/${elm?.user?.slug}`} >
                                                                                            {elm?.user?.first_name + " " + elm?.user?.last_name}
                                                                                        </Dropdown.Item>
                                                                                    </>
                                                                                )) : null
                                                                        }
                                                                    </div>
                                                                </Card>
                                                            </Dropdown>
                                                        </div> : null
                                                }
                                            </div>

                                        </div>
                                        <div className="comment d-flex align-items-center" style={{ cursor: "pointer" }}
                                            onClick={() => { handleShow() }}>
                                            <i className="material-symbols-outlined me-2 md-18">chat_bubble_outline</i>
                                            {elm?.comment_count} comment
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" style={{ order: order2 }}>
                                <div className="image-block">
                                    {/* <Image src={blog2} className="img-fluid rounded w-100" alt="blog-img" /> */}
                                    <Link to={`/dashboard/blog/blog-detail/${elm?.slug}`}>
                                        <img src={elm?.default_image?.file_name ? elm?.default_image?.file_name : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                            className="img-fluid rounded w-100" alt="blog-img"
                                            style={{ maxHeight: "350px", objectFit: "contain" }} />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>

            {
                showCommentModal ?
                    <BlogCommentModal elm={elm} showCommentModal={showCommentModal} handleClose={handleClose} /> : null
            }

            {/* <Col lg="12">
                <Card className="card-block card-stretch card-height blog-list list-even">
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col md="6">
                                <div className="blog-description rounded p-2">
                                    <div className="date mb-2">
                                        <Link to="#" tabIndex="-1">4 Month ago</Link>
                                    </div>
                                    <h5 className="mb-2">Containing coronavirus spread comes</h5>
                                    <p>In the blogpost, the IMF experts observed, "Success in containing the virus comes at the price of slowing economic activity."</p> <Link to="#" tabIndex="-1" className="d-flex align-items-center">Read More<i className="material-symbols-outlined md-14 filled">arrow_forward_ios</i></Link>
                                    <div className="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between position-right-side">
                                        <div className="iq-media-group">
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon1} alt="" />
                                            </Link>
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon2} alt="" />
                                            </Link>
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon3} alt="" />
                                            </Link>
                                            <Link to="#" className="iq-media">
                                                <Image className="img-fluid rounded-circle" src={icon4} alt="" />
                                            </Link>
                                        </div>
                                        <div className="comment d-flex align-items-center"><i className="material-symbols-outlined me-2 md-18">chat_bubble_outline</i>7 comments</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="image-block">
                                    <Image src={blog2} className="img-fluid rounded w-100" alt="blog-img" />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col> */}
        </>
    )
}

export default BlogCard