import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import LoaderModal from '../../../components/loaderModal';

import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../../api/apihandler';
import { INFLUENCERS_LIST, FOLLOW_UNFOLLOW } from "../../../api/constants";

//profile-header
import ProfileHeader from '../../../components/profile-header'

// image
import img1 from '../../../assets/images/page-img/profile-bg2.jpg'
import img2 from '../../../assets/images/page-img/profile-bg1.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img8 from '../../../assets/images/page-img/profile-bg8.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import user13 from '../../../assets/images/user/13.jpg'
import user14 from '../../../assets/images/user/14.jpg'
import user15 from '../../../assets/images/user/15.jpg'
import user16 from '../../../assets/images/user/16.jpg'
import user17 from '../../../assets/images/user/17.jpg'
import user18 from '../../../assets/images/user/18.jpg'
import user19 from '../../../assets/images/user/19.jpg'
import NoDataFound from '../../../components/noDataFound';


const FriendList = () => {

    const navigate = useNavigate();
    const [influencersList, setInfluencersList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showLoader, setShowLoader] = useState(false);

    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsFirst(false)
    }, [isFirst]);

    useEffect(() => {
        //console.log("token",localStorage.getItem("authToken"))
        if (localStorage.getItem("uzoneAuthToken")) {
            setInfluencersList([])
            getInfluencersList()
        } else {
            navigate('/auth/sign-in')
        }
    }, []);

    async function getInfluencersList() {
        setShowLoader(true)
        try {
            const res = await apiHandler(INFLUENCERS_LIST, "POST", {
                page: page,
                limit: 10
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.users?.meta?.last_page)
                //console.log("profileRes", maxPage)
                if (res?.data?.data?.users?.meta?.total > 0) {
                    setInfluencersList(influencersList.concat(res?.data?.data?.users?.data))
                }
            } else {
                navigate('/dashboard/app/home-page')
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
                //console.log("profileRes",res)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function getFollowUnfollow(followerId, index, isFollowingVal) {
        try {
            const res = await apiHandler(FOLLOW_UNFOLLOW, "POST", {
                follower_id: followerId
            })
            if (res.data?.response?.status === 200) {
                let temporaryarray = influencersList.slice();
                temporaryarray[index]['is_following'] = isFollowingVal;
                setInfluencersList(temporaryarray);
            }
        } catch (error) {
            //console.error(error);
        }
        finally {
        }
    }


    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <ProfileHeader title="Schools List" img={img3} />
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        {influencersList?.length > 0 ? influencersList.map((elm, i) => (
                            <Col md={6}>
                                <Card className=" card-block card-stretch card-height">
                                    <Card.Body className=" profile-page p-0">
                                        <div className="profile-header-image">
                                            <div className="cover-container">
                                                <img loading="lazy" src={img1} alt="profile-bg" className="rounded img-fluid w-100" />
                                            </div>
                                            <div className="profile-info p-4">
                                                <div className="user-detail">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                                                        <div className="profile-detail d-flex">
                                                            <div className="profile-img pe-4">
                                                                <img style={{ height: 120, width: 120 }} loading="lazy" src={elm?.user_setting?.photo ?
                                                                    elm?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                                                    alt="profile-img" className="avatar-130 img-fluid" />
                                                            </div>
                                                            <div className="user-data-block">
                                                                <h4>
                                                                    <Link to={`/dashboard/app/friend-profile/${elm?.slug}`}>{elm?.first_name && (elm?.first_name + " " + elm?.last_name)}</Link>
                                                                </h4>
                                                                <h6>{elm?.slug && (elm?.slug)}</h6>
                                                                {/* <p>Lorem Ipsum is simply dummy text of the</p> */}
                                                            </div>
                                                        </div>
                                                        {
                                                            elm?.is_following == 0 ?
                                                                <Link to="#" className="me-3 btn btn-primary rounded"
                                                                    onClick={() => {
                                                                        getFollowUnfollow(elm?.id, i, 1)
                                                                    }}><i className="ri-user-add-line me-1"></i>Follow</Link> :
                                                                <Link to="#" className="me-3 btn btn-secondary rounded"
                                                                    onClick={() => {
                                                                        getFollowUnfollow(elm?.id, i, 0)
                                                                    }}>Following</Link>
                                                        }
                                                        {/* <button type="submit" className="btn btn-primary">Following</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )) : <NoDataFound />
                        }
                        {
                            page < maxPage ?
                                <li className="d-block text-center mb-0 pb-0">
                                    <Link to="#" className="me-3 btn btn-primary"
                                        onClick={() => {
                                            setPage(page + 1)
                                            getInfluencersList()
                                        }}>View More Request</Link>
                                </li> : null
                        }
                    </Row>
                </Container>
            </div>
        </>
    )

}

export default FriendList;