import React, { useState, useEffect } from 'react'
import { apiHandler } from '../../api/apihandler';
import { NOTIFICATION_COUNT, GET_CONNECTED_USERS, GENERAL_SETTINGS } from "../../api/constants";
import { io } from "socket.io-client";
import Header from '../../components/partials/dashboard/HeaderStyle/header'
import RightSidebar from '../../components/partials/dashboard/SidebarStyle/rightsidebar'
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'
import Footer from '../../components/partials/dashboard/FooterStyle/footer'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import { setIsOptimizing } from '../../store/setting/actions'
//default 
// import DefaultRouter from '../../router/default-router'

// share-offcanvas
// import ShareOffcanvas from '../../components/share-offcanvas'

//settingoffCanvas
import SettingOffCanvas from '../../components/setting/SettingOffCanvas'
import { Outlet, useNavigate } from 'react-router-dom'
import DefaultHeader from '../../components/partials/dashboard/HeaderStyle/defaultHeader';

const Default = () => {
    const dispatch = useDispatch()
    const socket = io("https://api.knowfirerisk.com/");
    const navigate = useNavigate();
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const [notificationCount, setNotificationCount] = useState(0);
    const [onlineUserList, setOnlineUserList] = useState([]);
    const [generalSettings, setGeneralSettings] = useState('');

    useEffect(() => {
        getGeneralSettings()
        if (localStorage.getItem("uzoneAuthToken")) {
            getNotificationCount()
            getOnlineUserListApi()
            socketLogedInSetup()
        } else {
            navigate('/dashboard/app/home-page')
        }
    }, []);

    function socketLogedInSetup() {
        const userId = profileData?.id;
        // Listen for events from the server
        socket.on('connect', () => {
            // console.log('Connected to the server');
            socket.emit("login", userId);
        });

        // Listen for events from the server
        socket.on('disconnect', () => {
            socket.emit("logedout", userId);
            // console.log('DisConnected from the server');
        });
        socket.on('error', () => {
            console.log('DisConnected from the server');
        });

        socket.on('notificationUpdate', (message) => {
            //console.log('logedin users list:', message);
            setNotificationCount(message)
            // console.log('notification Count socket:', message);
        });
        socket.on('logedinusers', (message) => {
            // console.log('logedin users list:', message);
            getOnlineUserListApi()
        });
        socket.on('userlogedout', (message) => {
            // console.log('logedin users list:', message);
            getOnlineUserListApi()
        });
        socket.on('mediaOptimized', (message) => {
            // console.log('optimized:', message);
            dispatch(setIsOptimizing(true));
        });
    }

    async function getGeneralSettings() {
            try {
                const res = await apiHandler(GENERAL_SETTINGS, "POST", {
                })
                if (res.data?.response?.status === 200) {
                    setGeneralSettings(res?.data?.data?.generalSettings)
                }
            } catch (error) {
                console.error(error);
            }
        }

    async function getNotificationCount() {
        try {
            const res = await apiHandler(NOTIFICATION_COUNT, "POST")
            if (res.data?.response?.status === 200) {
                setNotificationCount(res?.data?.data?.count)
                //console.log('notification Count:', res?.data?.data?.count);
            } else {
                //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    async function getOnlineUserListApi() {
        try {
            const res = await apiHandler(GET_CONNECTED_USERS, 'POST', {
                is_online: '1'
            });
            if (res.data?.response?.status === 200) {
                setOnlineUserList(res?.data?.data?.users?.data)
            } else {
                setOnlineUserList([])
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }
  
    return (
        <>
            {
                localStorage.getItem("uzoneAuthToken") ?
                    <Sidebar /> : null
            }
            {
                localStorage.getItem("uzoneAuthToken") ?
                    <Header notificationCount={notificationCount} /> : 
                    <DefaultHeader/>
            }
            <div className="main-content">
                {/* <div id="content-page" className="content-page"> */}
                {/* <DefaultRouter/> */}
                <Outlet />
                {/* </div> */}
            </div>
            {
                localStorage.getItem("uzoneAuthToken") ?
                profileData?.user_type === 4 ? null:
                    <RightSidebar onlineUserList={onlineUserList} /> : null
            }
            <div style={{background: "white", width: "72%", justifySelf: "center"}}>
            <Footer />
            </div>
            
            {/* <SettingOffCanvas /> */}
        </>
    )
}

export default Default
