import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
// images
import sideImage from '../assets/images/getappimg/img.webp'
import apple from '../assets/images/getappimg/1.svg'
import android from '../assets/images/getappimg/2.svg'

import { apiHandler } from '../api/apihandler';
import { ANNOUNCEMENT_API } from "../api/constants";


const Announcement = ({ }) => {
    const [announcementData, setAnnouncementData] = useState('');
    useEffect(() => {
        getAnnoucementApi()
    }, [])

    async function getAnnoucementApi() {
        try {
            const res = await apiHandler(ANNOUNCEMENT_API, "POST")
            if (res.data?.response?.status === 200) {
                setAnnouncementData(res?.data?.data?.publicAnnouncement)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    }

    return (
        <div className="d-flex flex-wrap" style={{ justifyContent: "", background: "#5c2a83", paddingTop: "15px", paddingBottom: "15px" }}>
            <div style={{ width: "15%", justifyContent: "center", paddingLeft: "15px" }}>
                <h3 style={{ color: "#FFF" }}>Announcement :</h3>
            </div>
            <div className="d-flex align-items-center" style={{ width: "85%", justifyContent: "left" }}>
                <div>
                    <h4 style={{ color: "#FFF" }}>{announcementData?.announcement_details} <b><Link to={announcementData?.link} style={{color: "#FFF"}}>{announcementData?.link_text}</Link></b></h4>
                    {/* <h4 style={{ marginTop: "5px", color: "#FFF" }}>Anywhere</h4> */}
                    {/* <p className="pt-3 pb-3" >Take classes on the go with the educrat app. Stream or download to <br />watch on the plane, the subway, or wherever you learn best.</p> */}
                    {/* <div >
                        <img src={apple} alt="loader" style={{ height: "60px" }} />
                        <img src={android} alt="loader" style={{ height: "60px", marginLeft: "18px" }} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Announcement
