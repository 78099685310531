import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, ModalFooter, Dropdown } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Loader from "react-js-loader";
import { apiHandler } from '../../api/apihandler';
import { LOGIN_WITH_EMAIL, LOGIN_WITH_EMAIL_OTP_MATCH, RESEND_OTP } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'

// images
import TicketBuyPopup from './ticketBuyPopup'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
// import state selectors
import { setUserData } from '../../store/setting/actions'


const PublicEventCard = ({ elm, getEventList, isPast }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [showTicketBuy, setShowTicketBuy] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [startCountDownTimer, setStartCountDownTimer] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const handleClose = () => {
        setStep(1)
        setEmail('')
        setOtp('')
        setShowSignUpModal(false)
    }

    useEffect(() => {

    }, [])

    // const countDownTimer = () => {
    //     const interval = setInterval(() => {
    //         if (seconds > 0) {
    //             setSeconds(seconds - 1);
    //         }
    //         if (seconds === 0) {
    //             if (minutes === 0) {
    //                 clearInterval(interval);
    //             } else {
    //                 setSeconds(59);
    //                 setMinutes(minutes - 1);
    //             }
    //         }
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }

    const handleSubmit = () => {
        if (step == 1) {
            if (email.toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                verifyEmail();
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: "Enter valid email!",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
        } else if (step == 2) {
            matchEmailOtp();
        }
    }

    async function verifyEmail() {
        const res = await apiHandler(LOGIN_WITH_EMAIL, "POST", {
            email: email
        })
        if (res.status === 200) {
            // setMinutes(0)
            // setSeconds(10)
            // setStartCountDownTimer(true)
            setStep(2)
        } else {
            Swal.fire({
                title: 'Error!',
                text: res?.response?.data?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }

    async function reSendOTPApi() {
        const res = await apiHandler(RESEND_OTP, "POST", {
            email: email
        })
        if (res.data?.response?.status === 200) {
            //addToast("OTP has been sent to your " + email, { appearance: 'success' });
            // setMinutes(0)
            // setSeconds(10)
            // setStartCountDownTimer(true)
        } else {
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
        }
    }

    async function matchEmailOtp() {
        const res = await apiHandler(LOGIN_WITH_EMAIL_OTP_MATCH, "POST", {
            email: email,
            otp: otp
        })
        if (res.status === 200) {
            localStorage.setItem('uzoneAuthToken', res?.data?.data?.token?.token)
            dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
            navigate('/')
        } else {
            Swal.fire({
                title: 'Error!',
                text: res?.response?.data?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }


    return (
        <>
            <div>
                <Card className="rounded  mb-0" >
                    <div className="event-images">
                        <Link to={`/dashboards/app/publicEventDetails/${elm?.slug}`}>
                            <img src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                className="img-fluid" alt="Responsive"
                                style={{ height: "186px", width: "100%", objectFit: "cover" }} />
                        </Link>
                    </div>
                    <Card.Body>
                        <div>
                            <div className="d-flex w-100">
                                {/* <div className="date-of-event">
                                    <span>{Moment(elm?.start_date).format('MMM')}</span>
                                    <h5>{Moment(elm?.start_date).format('DD')}</h5>
                                </div> */}
                                <div className="events-detail ms-3">
                                    <h5><Link to={`/dashboards/app/publicEventDetails/${elm?.slug}`}>{elm?.name}</Link></h5>
                                    <h6>{Moment(elm?.event?.start_time).format('DD MMMM YYYY') + " - " + Moment(elm?.event?.end_time).format('DD MMMM YYYY')}</h6>
                                                                                <h6>{Moment(elm?.event?.start_time).format('hh:mm A') + " - " + Moment(elm?.event?.end_time).format('hh:mm A')}</h6>
                                    <p>{elm?.city + ", " + elm?.state + ", " + elm?.zip}</p>
                                </div>
                            </div>
                            {
                                isPast ? null :
                                    <div className="d-flex">
                                        <button type="submit" className="btn btn-primary d-block w-100" style={{ marginRight: "5px" }}
                                            onClick={() => {
                                                if (localStorage.getItem("uzoneAuthToken")) {
                                                    setShowTicketBuy(true)
                                                } else {
                                                    Swal.fire({
                                                        title: 'Warning!',
                                                        text: 'Are you sure, want to participate this event?',
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        showConfirmButton: true,
                                                        //showDenyButton: true,
                                                        cancelButtonText: 'Cancel',
                                                        confirmButtonText: 'Sign In',
                                                        confirmButtonColor: "#5c518b"
                                                        //denyButtonText: 'Sign Up'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            //console.log("data",elm?.id)
                                                            localStorage.setItem('uzoneSelectedTicketId', elm?.id)
                                                            navigate('/auth/sign-in')
                                                        }
                                                        // else if (result.isDenied) {
                                                        //     navigate('/auth/sign-up')
                                                        // }
                                                    })
                                                }
                                            }}
                                        >Participate by ${elm?.price}</button>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="bg-transparent" className="btn btn-primary d-block w-20">
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className=" m-0 p-0">
                                                <Dropdown.Item className="pl-3 pr-3 pt-3" to="#"
                                                    onClick={() => {
                                                        if (localStorage.getItem("uzoneAuthToken")) {
                                                            setShowTicketBuy(true)
                                                        } else {
                                                            Swal.fire({
                                                                title: 'Warning!',
                                                                text: 'Are you sure, want to buy this ticket?',
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                showConfirmButton: true,
                                                                //showDenyButton: true,
                                                                cancelButtonText: 'Cancel',
                                                                confirmButtonText: 'Login with OTP',
                                                                confirmButtonColor: "#5c518b"
                                                                //denyButtonText: 'Sign Up'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    localStorage.setItem('uzoneSelectedTicketId', elm?.id)
                                                                    navigate('/auth/login-with-otp')
                                                                }
                                                            })
                                                        }
                                                        // navigate('/auth/sign-up')
                                                    }}>
                                                    <div className="d-flex align-items-top">
                                                        <i className="ri-coupon-line h4"></i>
                                                        <div className="data ms-2">
                                                            <h6>Buy a Ticket</h6>
                                                            <p className="mb-0">${elm?.ticket_price} per ticket</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                            }
                        </div>
                    </Card.Body>
                </Card>

                <TicketBuyPopup elm={elm} showTicketBuy={showTicketBuy} setShowTicketBuy={setShowTicketBuy} getEventList={""}/>

                {/* <Modal scrollable={true} show={showSignUpModal} onHide={handleClose} size="md" style={{ marginTop: 100 }}>
                    <Modal.Header className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                            <div className="w-100">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <div>
                                        <h5 className="mb-0 d-inline-block"><Link to="#">Sign Up</Link></h5>
                                        <p className="mb-0">{step + "/2"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="post-item" style={{ maxHeight: "500px" }}>
                            <Form className="mt-1">
                                {step == 1 && <Form.Group className="form-group">
                                    <Form.Label>Your Email Address *</Form.Label>
                                    <Form.Control type="email" className="mb-0" id="exampleInputEmail1" placeholder="Email address"
                                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>}
                                {step == 2 && <Form.Group className="form-group">
                                    <h5 className="mb-3">OTP sent to your {email}</h5>
                                    <Form.Control type="text" className="mb-0" id="exampleInputEmail2" placeholder="Enter OTP"
                                        value={otp} onChange={(e) => setOtp(e.target.value)} />
                                </Form.Group>}
                                <div className="d-inline-block w-100">
                                    <Button type="button" className="btn-primary float-end" onClick={() => {
                                        handleSubmit()
                                    }}>
                                        {step == 2 ? 'Register' : 'Next'}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal> */}
            </div>

        </>
    )
}

export default PublicEventCard
