import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import CustomToggle from '../dropdowns'
import ShareOffcanvas from '../share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import Loader from "react-js-loader";
import { apiHandler } from '../../api/apihandler';
import { EVENT_MEMBERS } from "../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

// images
import user05 from '../../assets/images/user/05.jpg'
import user06 from '../../assets/images/user/06.jpg'
import user07 from '../../assets/images/user/07.jpg'
import user08 from '../../assets/images/user/08.jpg'
import user09 from '../../assets/images/user/09.jpg'
import TicketBuyPopup from './ticketBuyPopup'
import EventParticipateModal from './eventParticipateModal'
import ParticipatedMemberModal from './participatedMemberModal'
// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;


const MyEventCard = ({ elm, getEventList, isPast }) => {
    const navigate = useNavigate();
    const [memberList, setMemberList] = useState([]);
    const [showTicketBuy, setShowTicketBuy] = useState(false);
    const [showParticipatedMember, setShowParticipatedMember] = useState(false);
    const [showParticipateModal, setShowParticipateModal] = useState(false);

    useEffect(() => {
        //getMembersList()
    }, [])

    async function getMembersList() {
        try {
            const res = await apiHandler(EVENT_MEMBERS, "POST", {
                event_id: elm?.id,
                page: 1,
                limit: 6
            })
            if (res.data?.response?.status === 200) {
                setMemberList(res?.data?.data?.eventParticipants?.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    return (
        <>
            {/* <div style={{ width: "33%" }}> */}
            <div>
                <Card className="rounded  mb-0" >
                    <div className="event-images">
                        <Link to={`/dashboards/app/event-detail/${elm?.slug}`}>
                            <img src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                className="img-fluid" alt="Responsive"
                                style={{ height: "180px", width: "100%", objectFit: "initial" }} />
                        </Link>
                    </div>
                    <Card.Body>
                        <div>
                            <div className="d-flex w-100">
                                <div className="date-of-event">
                                    <span>{Moment(elm?.created_at).format('MMM')}</span>
                                    <h5>{Moment(elm?.created_at).format('DD')}</h5>
                                </div>
                                <div className="events-detail ms-3">
                                    <h5><Link to={`/dashboards/app/event-detail/${elm?.slug}`}
                                        onClick={() => {
                                            localStorage.setItem('eventIsPast', isPast)
                                        }}>{elm?.name}</Link></h5>

                                    {/* <h5><Link to={`/dashboards/app/event-detail/${elm?.slug}`}>{elm?.name}</Link></h5> */}
                                    {/* <h5>{"$" + elm?.price}</h5> */}
                                    <p>{elm?.city + ", " + elm?.state + ", " + elm?.zip}</p>
                                    {/* <div className="event-member">
                                        <div className="iq-media-group">
                                            {
                                                memberList?.length > 0 ? memberList.map((memberElm, i) => (
                                                    <Link to="#" className="iq-media" key={memberElm?.id}>
                                                        <img className="img-fluid avatar-40 rounded-circle" src={memberElm?.member?.user_setting?.photo ? memberElm?.member?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                                    </Link>
                                                )) : null}
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="card-post-toolbar">
                                    <h5>{"$" + elm?.price}</h5>
                                </div> */}
                            </div>
                            {
                                isPast ? null :
                                    <div className="d-flex">
                                        <button type="submit" className="btn btn-primary d-block w-100" style={{ marginRight: "5px" }}
                                            onClick={() => {
                                                if (localStorage.getItem("uzoneAuthToken")) {
                                                    setShowParticipatedMember(true)
                                                } else {
                                                    navigate('/auth/sign-in')
                                                }
                                            }}
                                        >Participated Member</button>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="bg-transparent" className="btn btn-primary d-block w-20">
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className=" m-0 p-0">
                                                <Dropdown.Item className="pl-3 pr-3 pt-3" to="#"
                                                    onClick={() => {
                                                        if (localStorage.getItem("uzoneAuthToken")) {
                                                            setShowParticipateModal(true)
                                                        } else {
                                                            navigate('/auth/sign-in')
                                                        }
                                                    }}>
                                                    <div className="d-flex align-items-top">
                                                        <i className="ri-coupon-line h4"></i>
                                                        <div className="data ms-2">
                                                            <h6>Participate</h6>
                                                            <p className="mb-0">Add members for participation</p>
                                                        </div>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                            }
                        </div>
                    </Card.Body>
                </Card>

                <TicketBuyPopup elm={elm} showTicketBuy={showTicketBuy} setShowTicketBuy={setShowTicketBuy} getEventList={getEventList}/>
                {
                    showParticipatedMember ?
                        <ParticipatedMemberModal
                            showParticipateModal={showParticipatedMember}
                            setShowParticipateModal={setShowParticipatedMember}
                            eventDetails={elm} /> : null
                }
                {
                    showParticipateModal ?
                        <EventParticipateModal
                            showParticipateModal={showParticipateModal}
                            setShowParticipateModal={setShowParticipateModal}
                            eventDetails={elm} /> : null
                }
            </div>

        </>
    )
}

export default MyEventCard
